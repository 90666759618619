<template>
    <div class="country-score-info section-card">
        <div class="country-score-info__title">
            {{ $t("Country Score") }}
        </div>
        <div class="country-score-info__text">
            {{ $t("Based on all your banks/filters applied") }}
        </div>
        <div class="country-score-info__content">
            <div class="d-flex align-items-center justify-content-center">
                <CountryImage
                    :imagePath="scoreSectionData.country.imagePath"
                    :score="scoreSectionData.stats.score"
                />
            </div>
            <div class="country-stats">
                <div class="country-stats__item score" :class="getStatusClass(scoreSectionData.stats.score)">
                    <div class="country-stats-indicator"></div>
                    <span>{{ $t("Score") }}</span>
                    <strong>{{ scoreSectionData.stats.score }}</strong>
                </div>
                <div class="country-stats__item importance">
                    <div class="country-stats-indicator"></div>
                    <span>{{ $t("Imp") }}</span>
                    <strong>{{ scoreSectionData.stats.importance }}</strong>
                </div>
                <div class="country-stats__item gap" :class="getGapStatusClass(scoreSectionData.stats.gap)">
                    <div class="country-stats-indicator"></div>
                    <span>{{ $t("Gap") }}</span>
                    <strong>{{ scoreSectionData.stats.gap }}</strong>
                </div>
                <div class="country-stats__item">
                    <div class="country-stats-indicator"></div>
                    <span>{{ $t("Respondents") }}</span>
                    <strong>{{ scoreSectionData.stats.respondents }}</strong>
                </div>
                <div class="country-stats__item">
                    <div class="country-stats-indicator"></div>
                    <span>{{ $t("Scores") }}</span>
                    <strong>{{ scoreSectionData.stats.scores }}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGapStatusClass, getStatusClass } from "@/core/helpers/GlobalHelper";
import CountryImage from "@/buying-teams/shared-components/charts/CountryImage";

export default {
    name: "CountryScoreInfo",
    props: {
        scoreSectionData: Object
    },
    data() {
        return {
            getStatusClass,
            getGapStatusClass
        };
    },
    components: {
        CountryImage,
    }
};
</script>

<style scoped lang="scss">
.country-score-info {
    padding: 16px 13px 16px 22px;

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #343434;
        margin-bottom: 7px;
    }

    &__text {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 20px;
    }

    &__content {
        display: flex;

        .map-content {
            background: #F9F9FF;
            border-radius: 10.5685px;
            width: 244px;
            height: 212px;
            padding: 10px;
        }

        .country-stats {
            width: calc(100% - 244px);
            padding-left: 26px;

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 16px;
                position: relative;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(54, 54, 54, 0.1);
                    margin-bottom: 16px;
                }

                span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    opacity: 0.5;
                }

                strong {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #313131;
                }

                &:first-child {
                    span, strong {
                        font-weight: 700;
                    }
                }

                .country-stats-indicator {
                    position: absolute;
                    background: #ECECEC;
                    width: 8.3px;
                    height: 8.3px;
                    left: -14px;
                    top: 4px;
                    border-radius: 50%;
                }

                &.importance {
                    .country-stats-indicator {
                        background: #CED4F8;
                    }
                }

                &.score {
                    &.status-danger {
                        .country-stats-indicator {
                            background: #E22D21;
                        }
                    }

                    &.status-warning {
                        .country-stats-indicator {
                            background: #FBDC6C;
                        }
                    }

                    &.status-success {
                        .country-stats-indicator {
                            background: #2BC490;
                        }
                    }
                }

                &.gap {
                    .country-stats-indicator {
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 6px;
                        }
                    }

                    &.status-success {
                        .country-stats-indicator {
                            background-color: #DBF7ED;

                            &:after {
                                background-image: url("/media/buying/icons/gap-value-icon.svg");
                                filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                            }
                        }
                    }

                    &.status-danger {
                        .country-stats-indicator {
                            background-color: #FBDDDB;

                            &:after {
                                background-image: url("/media/buying/icons/gap-value-icon.svg");
                                filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="specific-product-area-filter" v-if="isReady">
        <div class="specific-product-area-filter__top">
            <div class="specific-product-area-filter__top--title">
                <span>{{ $t("Filters") }}</span>
                <a
                    v-if="showFilterBadges"
                    @click.prevent="setFiltersDefaultState"
                    href="">{{ $t("Clear All") }}
                </a>
            </div>

            <FiltersScoreImportanceInfo />
        </div>

        <div class="specific-product-area-filter__card section-card">
            <div class="specific-product-area-filter__item">
                <div class="specific-product-area-filter__item--label">{{ $t("Banks") }}</div>
                <SelectBoxWithModal
                    v-model="filterData.bank_ids"
                    multiple
                    show-badge
                    :options="filterAllData.all_bank_ids"
                    :all-selected-text="$t(filterAllSelectedText(BusinessFiltersSelectTypeEnum.BANKS))"
                    :custom-selection-text="$t(filterCustomSelectionText(BusinessFiltersSelectTypeEnum.BANKS))"
                    @onTouch="isFilterTouched = true"
                />
                <div v-if="isFilterTouched && !filterData.bank_ids.length" class="text-danger mt-1">
                    {{ $t("Please select bank") }}
                </div>
            </div>
            <div class="specific-product-area-filter__item">
                <div class="specific-product-area-filter__item--label">{{ $t("Product Areas") }}</div>
                <SelectBoxWithModal
                    v-model="filterData.product_areas"
                    self-value
                    multiple
                    show-badge
                    :options="filterAllData.all_product_areas"
                    :all-selected-text="$t(filterAllSelectedText(BusinessFiltersSelectTypeEnum.PRODUCT_AREAS))"
                    :custom-selection-text="$t(filterCustomSelectionText(BusinessFiltersSelectTypeEnum.PRODUCT_AREAS))"
                    @onTouch="isFilterTouched = true"
                />
                <div v-if="isFilterTouched && !filterData.product_areas.length" class="text-danger mt-1">
                    {{ $t("Please select product area") }}
                </div>
            </div>
            <div class="specific-product-area-filter__item">
                <div class="specific-product-area-filter__item--label">{{ $t("Time Period") }}</div>
                <DateRangePicker
                    @onTouch="isFilterTouched = true"
                    :all-time-selection-value="businessBaseFilterDefaultState.time_period"
                    v-model="filterData.time_period"
                />
            </div>
            <div class="specific-product-area-filter__item apply-section">
                <button
                    :disabled="disabled || !isFilterValid"
                    @click="handleApplyFilters"
                    class="main-btn btn apply-section__btn"
                >
                    {{ $t("Apply") }}
                    <span
                        v-show="isFilterTouched && !isFilterApplied"
                        class="apply-section__btn--badge"
                    >
                        <BusinessFilterAppliedBadges
                            :filterData="filterData"
                            :allData="filterAllData"
                            :forceShow="showFilterBadges === 0"
                            @getSize="handleGetSize"
                        />
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FiltersScoreImportanceInfo from "@/buying-teams/shared-components/utils/FiltersScoreImportanceInfo";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import store from "@/store";
import BusinessFilterAppliedBadges from "@/buying-teams/shared-components/filter/BusinessFilterAppliedBadges";
import { globalFilterValidator } from "@/core/helpers/GlobalHelper";
import { BusinessFiltersSelectTypeEnum } from "@/store/enums/business/BusinessFiltersSelectTypeEnum";
import { filterAllSelectedText, filterCustomSelectionText } from "@/core/helpers/FiltersHelper";

export default {
    name: "SpecificCountryFilter",
    components: {
        FiltersScoreImportanceInfo,
        BusinessFilterAppliedBadges,
        DateRangePicker,
        SelectBoxWithModal,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        dashboardSingleCountryFiltersState: Object,
    },
    emits: ["onApplyFilters"],
    data() {
        return {
            isFilterTouched: false,
            showFilterBadges: false,
            isReady: false,
            filterData: {
                bank_ids: [],
                product_areas: [],
                time_period: {
                    start_date: "",
                    end_date: ""
                }
            },
            isFilterApplied: true,
            BusinessFiltersSelectTypeEnum,
            filterAllSelectedText,
            filterCustomSelectionText
        };
    },
    mounted() {
        if (this.dashboardSingleCountryFiltersState) {
            this.filterData = this.dashboardSingleCountryFiltersState;
        }
        this.isReady = true;
    },
    computed: {
        filterAllData() {
            return {
                all_product_areas: store.getters.businessProductAreas,
                all_bank_ids: store.getters.businessBanks
            };
        },
        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        },
        isFilterValid() {
            return globalFilterValidator(this.filterData);
        }
    },
    methods: {
        handleApplyFilters() {
            this.isFilterApplied = true;
            this.$emit("onApplyFilters", this.filterData);
        },
        setFiltersDefaultState() {
            this.filterData.bank_ids = this.filterAllData.all_bank_ids.map(bk => bk.id);
            this.filterData.product_areas = this.filterAllData.all_product_areas;
            this.filterData.time_period = this.businessBaseFilterDefaultState.time_period;
            this.isFilterApplied = false;
            this.isFilterTouched = false;
        },
        handleGetSize(touchedCount) {
            this.isFilterApplied = false;
            this.showFilterBadges = touchedCount;
        },
        setFilterApplied() {
            setTimeout(() => {
                this.isFilterApplied = true;
            })
        }
    }
};
</script>

<style scoped lang="scss">
.specific-product-area-filter {
    width: 100%;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        margin-bottom: 8px;

        &--title {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #434343;
                opacity: 0.6;
            }

            a {
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                color: #CA0F0F;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &__card {
        padding: 16px 14px 18px 15px;
        display: flex;
        gap: 13px;
    }

    &__item {
        width: 100%;
        max-width: 212px;

        &--label {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #434343;
            opacity: 0.6;
            margin-bottom: 3px;
        }

        .filter-search-input {
            width: 100%;
            outline: none;
            height: 56px;
            border: none;
            background: #F6F6F6;
            border-radius: 5.28234px;
            padding: 13px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            &::placeholder {
                color: rgba(67, 67, 67, 0.6);
            }
        }
    }

    .apply-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        max-width: revert;
        padding-left: 13px;

        &__btn {
            min-width: 126px;
            position: relative;

            &--badge {
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }

    @media (max-width: 1440px) {
        &__card {
            flex-wrap: wrap;
        }
        &__item {
            width: calc(50% - 7.5px);
            max-width: revert;
        }
        .apply-section {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        &__top {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }
        &__item {
            width: 100%;
        }
    }
}
</style>
